<template>
<div>
      <b-skeleton-table v-if="loading" :columns="2" :rows="8"></b-skeleton-table>
      <b-container v-else :key="ticks">
            <b-row class="mb-1">
                  <b-col  cols="12" md="6" class="font-weight-bold">Matchnummer</b-col>
                  <b-col  cols="12" md="6" >
                        <b-input v-model="game.gamenr" placeholder="Matchnr..."></b-input>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col  cols="12" md="6" class="font-weight-bold">Speldag</b-col>
                  <b-col  cols="12" md="6">
                        <b-input v-model="gdate" placeholder="YYYY-MM-DD"></b-input>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col  cols="12" md="6" class="font-weight-bold">Tid</b-col>
                  <b-col  cols="12" md="6">
                        <b-input v-model="gtime" placeholder="HH:mm"></b-input>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col  cols="12" md="6" class="font-weight-bold">Hemmalag</b-col>
                  <b-col  cols="12" md="6">
                        <b-select v-model="game.hometeamid" :options="teams"></b-select>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col  cols="12" md="6" class="font-weight-bold">Bortalag</b-col>
                  <b-col  cols="12" md="6">
                        <b-select v-model="game.awayteamid" :options="teams"></b-select>
                  </b-col>
            </b-row>

            <b-row class="mt-2" v-if="haserror">
                  <b-col  cols="12" md="6">
                        <Alert variant="danger" v-for="(err, index) in terrors" :key="index" >{{err}}</Alert>
                  </b-col>
            </b-row>

            <hr />

            <b-row class="mb-1 mt-2">
                  <b-col  cols="12" md="6" class="text-center">
                        <b-button class="mx-1" @click="Cancel()" variant="secondary">Avbryt</b-button>
                        <b-button class="mx-1" @click="AddGame()" variant="primary">Spara</b-button>
                  </b-col>
            </b-row>
      </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");

export default {
      Name: "AddEditGame",
      props: {
            "game": Object,
            "group": Object
      },
      components: {
            Alert
      },
      data() {
            return {
                  terrors: [],
                  ticks: 1,
                  gdate: "",
                  gtime: ""
            }
      },
      computed: {
            loading() {
                  return this.$store.getters["adminpost/gameloading"];
            },
            haserror() {
                  return this.$store.getters["adminpost/gameerror"];
            },
            errors(){

            },
            teams() {
                  var teams = [];
                  for (var i = 0; i < this.group.teams.length; i++) {
                        var t = this.$functions.getTeam(this.group.teams[i]);
                        teams.push({
                              value: t.id,
                              text: t.dispname
                        });
                  }
                  var sorted = this.$functions.sort(teams, "text");
                  sorted.unshift({
                        value: 0,
                        text: "Bestäms senare"
                  });
                  return sorted;
            }
      },
      methods: {
            AddGame() {
                  this.$store.dispatch("adminpost/AddGame", this.game).then((response) => {
                        if (response.status == "ok") {
                              this.$emit("saved", response.message);
                        } else {
                              this.terrors = response.errors;
                        }
                  });
            },
            Cancel() {
                  this.$emit("cancel");
            }           
      },
      watch: {
            gtime(value) {
                  this.game.gamedate = this.gdate + " " + value;
            },
            gdate(value) {
                  this.game.gamedate = value + " " + this.gtime;
            }
      },
      mounted() {
            var date = this.$date.GameDateFull(this.game.gamedate);
            var time = this.$date.GameTime(this.game.gamedate);
            this.gdate = date;
            this.gtime = time;
            this.game.groupid = this.group.id;
      }
};
</script>
